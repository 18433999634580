/* eslint-disable @nx/enforce-module-boundaries */
import { SendIcon } from '@mybridge/icons/Send';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@mybridge/ui/form-control';
import { IconButton } from '@mybridge/ui/icon-button';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Textarea } from '@mybridge/ui/text-area';
import { Tooltip } from '@mybridge/ui/tooltip';
import { SlideFade } from '@mybridge/ui/transitions';
import { useContext, useEffect } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { AttachedMediaContainer } from './attached-media-display';
import { PostCommentFormContext } from './context';
import { EmojiPicker } from './emoji-picker';
import { GifPicker } from './gif-picker';
import { MediaPicker } from './media-picker';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const CommentForm = ({ maxLength = 250, ...props }) => {
  const {
    expanded,
    setExpanded,
    text,
    setText,
    submit,
    isLoading,
    textInputRef,
    hasContent,
    parent,
  } = useContext(PostCommentFormContext);

  const handleFieldFocus = () => {
    // setCommentBoxExapnded && setCommentBoxExapnded(true);
    setExpanded && setExpanded(true);
  };
  const { t } = useTranslator();
  const handleTextChange = (e) => {
    setText?.(e?.target?.value);
  };

  useEffect(() => {
    if (parent?.owner) {
      setText(``);
    }
  }, [parent]);

  return (
    <HStack flex={1}>
      <Stack
        w="100%"
        // onFocus={handleFieldFocus}
        onClick={handleFieldFocus}
        transition="all 0.2s"
        bg="gray.100"
        minH={expanded ? '20' : '10'}
        borderRadius="xl"
        spacing={0}
        pos="relative"
        
      >
        <form onSubmit={() => submit(props.currentPostCommenter)}>
          <FormControl isInvalid={text?.length > maxLength} pos="relative">
            {parent?.owner && (
            <Box pt="40px"> 
              <Box
                bg="#AFDBFB"
                fontWeight="600"
                p="0 12px"
                
                display="inline-block"
                pos="absolute"
                zIndex="3"
                top="8px"
                left="14px"
              >
                {getUserFullName(parent?.owner)}
              </Box> </Box>
            )}
            <Textarea
              ref={textInputRef}
              resize="none"
              transition="all 0.2s"
              bg="transparent"
              _focusVisible={{ boxShadow: 'none' }}
              placeholder="Add comment"
              border="none"
              minH={expanded ? '16' : '10'}
              py={2}
              paddingRight="12"
              onChange={handleTextChange}
              value={text}
              maxLength="250"
              sx={{
                '::placeholder': {
                  marginLeft: '8px', // Adjust this value as needed
                },
              }}
            />
            {expanded ? (
              <HStack px={4}>
                <FormErrorMessage>
                {t('otherPage.shouldnotbe')} {maxLength} {t('otherPage.chars')||'chars'}
                </FormErrorMessage>
                <HStack flex={1} justifyContent="flex-end">
                  <FormHelperText>
                    {text?.length ?? 0}/{maxLength}
                  </FormHelperText>
                </HStack>
              </HStack>
            ) : (
              ''
            )}
          </FormControl>
        </form>
        {expanded ? (
          <HStack pos="absolute" top="2" right="2">
            <Tooltip hasArrow placement="top" variant="round" label="Send">
              <IconButton
                isDisabled={!hasContent || text?.length > maxLength}
                type="submit"
                onClick={() => submit(props.currentPostCommenter)}
                isLoading={isLoading}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </HStack>
        ) : (
          ''
        )}
        <AttachedMediaContainer />
        <Box transition="all 0.2s" h={expanded ? '50px' : '0'}>
          <SlideFade direction="bottom" in={expanded}>
            <HStack p={2}>
              <HStack display={expanded ? 'flex' : 'none'}>
                <MediaPicker />
                <GifPicker />
                <EmojiPicker />
              </HStack>
            </HStack>
          </SlideFade>
        </Box>
      </Stack>
    </HStack>
  );
};
